:root {
  --clickable: #621fbb;
  --clickableHovered: rgb(66, 0, 155);
  --activeColour: #da6cfb;
  --calmingColour: #1fb3ff;
  --darkBlue: #5857cf;
  --tikTokColour: #982d87;
  --green: #159a27;
  --green-hovered: #007a07;
  --yellow: #b99417;
  --yellow-hovered: #997400;
  --red: #d40101;
  --red-hovered: #c40000;
  --red-disabled: #a40000;
  --grey-background: rgb(250, 250, 250);
  --greyer-background: rgb(240, 240, 240);
  --clickable-subtle: rgb(98, 31, 187, 0.2);
  --grey: rgba(0, 0, 0, 0.275);

  --feedback-very-bad: #d40101;
  --feedback-bad: #b28e18;
  --feedback-neutral: #eec336;

  --feedback-good: #159a27;
  --feedback-very-good: #16db30;

  --default-font-colour: #404040;

  --card-border-colour: rgba(0, 0, 0, 0.175);

  --hr-colour: rgb(33, 37, 41, 0.25);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.App {
  font-family: "Open Sans";
}

.greenBorder {
  border-color: var(--green) !important;
}

.yellowBorder {
  border-color: var(--yellow) !important;
}

.redBorder {
  border-color: var(--red) !important;
}

.activeColourFont {
  color: var(--activeColour);
}

.activeColourBorder {
  border-color: var(--activeColour) !important;
  border-radius: 4% !important;
}

.darkBlueFont {
  color: var(--darkBlue);
}

.darkBlueBorder {
  border-color: var(--darkBlue) !important;
  border-radius: 4% !important;
}

.tikTokColourFont {
  color: var(--tikTokColour);
}

.tikTokColourBorder {
  border-color: var(--tikTokColour) !important;
  border-radius: 4% !important;
}

.pageTitle {
  text-align: center;
  font-weight: 700;
  color: var(--clickable) !important;
}

.headingColour {
  color: var(--clickable);
}

.mainButton {
  background-color: var(--clickable) !important;
  border-radius: 100px !important;
  border-color: var(--clickable) !important;
}

.mainButton:hover {
  background-color: var(--clickableHovered) !important;
  border-radius: 100px !important;
  border-color: var(--clickableHovered) !important;
}

.mainButton:active {
  background-color: var(--clickableHovered) !important;
  border-radius: 100px !important;
  border-color: var(--clickableHovered) !important;
}

.mainButton:focus {
  background-color: var(--clickableHovered) !important;
  border-radius: 100px;
  border-color: var(--clickableHovered) !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.2%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin: 0 auto;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.light-line {
  border-top: 2px solid #a0a096;
}

.navbar {
  /* border-bottom: 1px solid #cccccc !important;   */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.grow:hover:not(:has(.workout-share-button:hover)) {
  transform: scale(1.015);
}

.grow {
  cursor: pointer;
}

.grow-big:hover {
  transform: scale(1.03);
}
.grow-big {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainBody {
  min-height: 100rem;
}

.sm-margin-bottom {
  margin-bottom: 20px;
}

.full-screen-player {
  height: 70vh;

  width: 100%;
  position: relative;
}

.full-screen-player-m {
  min-height: 50vh;

  width: 100%;
  position: relative;
}

.player-container:hover .player-overlay {
  /* opacity: 1; */
  animation: show-video-icons 3s 1;
}

.player-container-ipad .player-overlay {
  /* opacity: 1; */
  animation: show-video-icons 1s 1;
}

.player-container-m .player-overlay {
  /* opacity: 1; */
  animation: show-video-icons 1s 1;
}

@keyframes show-video-icons {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* @keyframes hide-video-icons {
  0% {
    opacity: 0.3;
  }

  90% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.player-container:hover .player-content {
  animation: hide-video-icons 3s 1;
} */

.player-overlay {
  /* transition: .5s ease; */
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.react-player-full {
  height: 100% !important;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.react-player-full-m {
  /* height: 100% !important; */
  margin: auto;

  /* margin-top: -30px; */
}

.dashboard-line {
  margin-left: -12px;
  margin-right: -12px;
  z-index: 100;
  padding-bottom: 20px;
  box-shadow: 0px 15px 10px -15px #111;
}

.dashboard-body {
  margin-left: -12px;
  margin-right: -12px;
  background-color: #f5f6fa;
  padding: 2%;
  min-height: 100vh;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
}

@media screen and (max-width: 768px) {
  .dashboard-body {
    margin-left: -12px;
    margin-right: -12px;
    background-color: #f5f6fa;
    padding: 2%;
    padding-top: 25px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.dashboard-header-2 {
  padding-bottom: 20px;
}

.play-store-colour {
  color: rgb(1, 31, 223);
}

.christmas-colour {
  color: #da6cfb;
  /* background-color: rgb(240, 240, 240); */
}

.welcome-back-card {
  border-bottom: 5px solid var(--clickable) !important;
}

.ios-store-card {
  border-bottom: 5px solid black !important;
}

.instagram-store-card {
  border-bottom: 5px solid var(--activeColour) !important;
}

.tiktok-store-card {
  border-bottom: 5px solid var(--default-font-colour) !important;
}

.android-store-card {
  border-bottom: 5px solid rgb(1, 31, 223) !important;
}

.plan-card {
  /* color: rgb(203, 78, 0); */
  border-bottom: 5px solid rgb(203, 78, 0) !important;
}

.plan-card-highlight {
  /* color: rgb(203, 78, 0); */
  border: 1px solid rgb(203, 78, 0) !important;
  border-bottom: 5px solid rgb(203, 78, 0) !important;
  /* animation: card-grow-animation infinite 2s linear; */
}

@keyframes card-grow-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.col-border-right {
  border-right: 1px solid #dbdbd4;
}

.title-margin {
  margin-top: 10px;
}

.register-form-box {
  max-width: 600px !important;
  margin-top: 40px;
}

.help {
  border-color: var(--clickable) !important;
}

.highlight-subscription {
  color: var(--clickable) !important;
  font-weight: 700 !important;
  animation: subscription-spin infinite 5s linear;
}

@keyframes subscription-spin {
  40% {
    transform: rotate(0deg);
  }
  41% {
    transform: rotate(-10deg);
  }
  43% {
    transform: rotate(10deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  47% {
    transform: rotate(10deg);
  }
  48% {
    transform: rotate(0deg);
  }
}

.highlight-subscription-dropdown .dropdown-toggle#basic-nav-dropdown {
  color: var(--clickable) !important;
  font-weight: 700 !important;
  animation: subscription-grow infinite 2s linear;
}

@keyframes subscription-grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.disabled {
  opacity: 0.5 !important;
}

.structured-lesson-container {
  overflow: hidden;
}

.structured-lesson-list {
  padding: 0px !important;
  background-color: var(--grey-background);
  border-right: 1px solid var(--clickable) !important;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.structured-lesson-content {
  padding: 0px !important;
  background-color: white;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

body,
html {
  margin: 0;
  padding: 0;
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.left,
.right {
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  text-align: center;
}

.left {
  background-color: #eee;
}
.right {
  background-color: khaki;
}

.scroll {
  overflow: auto;
}

.structured-lesson-list-title {
  font-weight: 600 !important;
  font-size: large;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-top: 1px solid var(--grey) !important;
  border-bottom: 1px solid var(--grey) !important;
}

.structured-lesson-list-selected {
  font-weight: 900 !important;
  font-size: larger;
  color: var(--clickable);
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-top: 2px solid var(--clickable) !important;
  border-bottom: 2px solid var(--clickable) !important;
}

.structured-lesson-list-box {
  margin-top: 10px;
  margin-bottom: 50px;
}

.structured-lesson-video {
  height: 90%;
  width: 70%;
}

.structured-lesson-video-bordered {
  height: 90%;
  width: 70%;
  border: 1px solid var(--clickable);
  border-bottom: 6px solid var(--clickable);
  border-radius: 25px;
}

.structured-lesson-video-box {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 50px;
}

.video-responsive-structured {
  overflow: hidden;
  padding-bottom: 56.2%;
  position: relative;
  height: 0;
}

.video-responsive-structured iframe {
  left: 0;
  border-radius: 20px;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.structured-content-text-box {
  margin-left: 15%;
  margin-right: 15%;
}

.structured-content-heading {
  font-weight: 700;
  font-size: 2em;
  color: var(--clickable);
}

.structured-content-text {
  font-weight: 500;
  text-align: justify;
  font-size: larger;
}

.structured-content-text-em {
  font-weight: 700;
  text-align: justify;
  font-size: x-large;
}

.selected-lesson-list {
  /* transform: scale(1.03); */
  border-radius: 10px;
}

.not-selected-lesson-list {
  /* transform: scale(1.03); */
  border-radius: 10px;
}

.selected-lesson {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin-right: 10px;
}

.worksheet-box {
  padding: 0px !important;
  background-color: var(--grey-background);
}

.worksheet-box-title {
  font-weight: 700;
  font-size: large;
  padding: 10px;
}

.worksheet-url-box {
  font-weight: 700;
  font-size: large;
  padding: 10px;
  border-top: 2px solid white;
  color: var(--clickable);
  cursor: pointer;
}

.worksheet-url-box:hover {
  transform: scale(1.005);
}

.scroll-auto {
  overflow: auto;
  overflow-y: scroll;
  height: 100vh;
}

.scroll-none {
  overflow: hidden;
}

.structured-lesson-list-title-m {
  font-weight: 600 !important;
  font-size: large;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid var(--grey) !important;
}

.structured-lesson-list-box-m {
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--grey);
  border-radius: 10px;
}

.back-arrow {
  color: var(--clickable);
  font-size: xx-large;
  font-weight: 600;
  margin-right: 10px;
}

.structured-content-text-box-m {
  margin-left: 5%;
  margin-right: 5%;
}

.structured-content-heading-m {
  font-weight: 700;
  font-size: larger;
  color: var(--clickable);
  margin: 0px;
}

.structured-content-text-m {
  font-weight: 500;
  font-size: large;
}

.structured-content-text-em-m {
  font-weight: 700;
  font-size: x-large;
}

.structured-lesson-video-m {
  height: 100%;
  width: 90%;
}

.structured-lesson-video-box-m {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.hr-mobile {
  margin-left: 5%;
  margin-right: 5%;
}

.worksheet-box-title-m {
  font-weight: 700;
  font-size: medium;
  padding: 10px;
}

.worksheet-url-box-m {
  font-weight: 700;
  font-size: medium;
  padding: 10px;
  border-top: 2px solid white;
  color: var(--clickable);
  cursor: pointer;
}

.worksheet-url-box-m:hover {
  transform: scale(1.005);
}

.structured-lesson-item {
  margin: 10px;
  min-width: 160px;
  min-height: 240px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: white;
}

.structured-lesson-item-title {
  min-width: 160px;
  min-height: 180px;
  padding: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.structured-lesson-item-title-text {
  margin: auto;
  color: white;
  font-weight: 600;
  font-size: xx-large;
  width: 100%;
  position: absolute;
  top: 40%;
  padding-left: 20px;
}

.box-purple {
  background-color: var(--activeColour);
  color: white;
}

.box-yellow {
  background-color: var(--yellow);
  color: white;
}

.box-green {
  background-color: var(--green);
  color: white;
}

.box-blue {
  background-color: var(--darkBlue);
  color: white;
}

.box-red {
  background-color: var(--red);
  color: white;
}

.box-white {
  background-color: white;
  color: #222;
}

.box-activecolour {
  background-color: var(--activeColour);
  color: white;
}
.box-tiktokcolour {
  background-color: var(--tikTokColour);
  color: white;
}

.box-calmingcolour {
  background-color: var(--calmingColour);
  color: white;
}

.box-christmascolour {
  background-color: var(--activeColour);
  color: white;
}

.box-textprimary {
  background-color: var(--default-font-colour);
  color: white;
}

.border-purple {
  border: 1px solid var(--activeColour);
}

.border-yellow {
  border: 1px solid var(--yellow);
}

.border-green {
  border: 1px solid var(--green);
}

.border-blue {
  border: 1px solid var(--darkBlue);
}

.border-red {
  border: 1px solid var(--red);
}

.border-white {
  border: 1px solid white;
}
.border-tiktokcolour {
  border: 1px solid var(--tikTokColour);
}

.border-activecolour {
  border: 1px solid var(--activeColour);
}

.border-calmingcolour {
  border: 1px solid var(--calmingColour);
}

.border-christmascolour {
  border: 1px solid var(--activeColour);
}

.border-textprimary {
  border: 1px solid var(--default-font-colour);
}

.structured-lesson-item-content {
  min-width: 160px;
  min-height: 60px;
  padding: 10px;
  font-size: x-large;
  font-weight: 600;
  border-radius: 0px 0px 10px 10px;

  text-align: center;
  /* background-color: white !important; */
}

.structured-lesson-list-container {
  display: flex;
  align-items: center;
}

.box-disabled {
  opacity: 0.3;
}

.box-enabled {
  cursor: pointer;
}

.box-enabled:hover {
  transform: scale(1.03);
}

.edit-lesson-button-container {
  text-align: center;
}

.title-edit-form {
  text-align: center;
  font-size: xx-large !important;
  color: var(--clickable) !important;
}

.button-no-colour {
  border-radius: 100px !important;
}

.add-lesson-container {
  min-height: 50vh;
}

.structured-lesson-list-supergroup-title {
  font-weight: 600 !important;
  font-size: xx-large;
  text-align: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-top: 1px solid var(--grey) !important;
  border-bottom: 1px solid var(--grey) !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 99% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  margin: 0 auto;
  width: 99% !important;
  height: 100% !important;
}

.plan-arrow {
  color: var(--clickable);
  font-size: 4rem;
  font-weight: 600;
  margin-right: 10px;
  cursor: pointer;
}

.plan-arrow :hover {
  transform: scale(1.1);
}

.feedback-box {
  text-align: center;
}

.feedback-very-bad {
  color: var(--feedback-very-bad);
  font-size: 5rem !important;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-very-bad:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-bad {
  color: var(--feedback-bad);
  font-size: 5rem !important;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-bad:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-neutral {
  color: var(--feedback-neutral);
  font-size: 5rem !important;
  cursor: pointer;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-neutral:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-good {
  color: var(--feedback-good);
  font-size: 5rem !important;
  cursor: pointer;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-good:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-very-good {
  color: var(--feedback-very-good);
  font-size: 5rem !important;
  cursor: pointer;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-very-good:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-very-bad-m {
  color: var(--feedback-very-bad);
  font-size: 3.5rem !important;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-very-bad-m:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-bad-m {
  color: var(--feedback-bad);
  font-size: 3.5rem !important;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-bad-m:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-neutral-m {
  color: var(--feedback-neutral);
  font-size: 3.5rem !important;
  cursor: pointer;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-neutral-m:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-good-m {
  color: var(--feedback-good);
  font-size: 3.5rem !important;
  cursor: pointer;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-good-m:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.feedback-very-good-m {
  color: var(--feedback-very-good);
  font-size: 3.5rem !important;
  cursor: pointer;
  cursor: pointer;
  opacity: 0.5;
}

.feedback-very-good-m:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

.new-item-animation {
  animation: subscription-grow infinite 2s linear;
}

.nav-highlight {
  vertical-align: middle;
  color: var(--clickable) !important;
  font-weight: 600 !important;
}

.nav-highlight:hover {
  color: var(--clickableHovered) !important;
  font-weight: 600 !important;
}

.highlight-border {
  border: 1px solid var(--clickable);
  border-bottom: 6px solid var(--clickable);
  border-radius: 10px;
}

.white-body {
  margin-left: -12px;
  margin-right: -12px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.button-padding {
  padding-left: 3% !important;
  padding-right: 3% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: large !important;
  font-weight: 700 !important;
}

.pricing-card-1 {
  max-width: 99%;
  border: 1px solid var(--green) !important;
  border-bottom: 6px solid var(--green) !important;
  margin-bottom: 10%;
}

.pricing-card-2 {
  max-width: 99%;
  border: 1px solid var(--yellow) !important;
  border-bottom: 6px solid var(--yellow) !important;
  margin-bottom: 10%;
}

.pricing-card-picture {
  height: 15rem;
  width: 50%;
  margin: auto;
  object-fit: contain;
}

.text-justify {
  text-align: justify !important;
}

.pricing-card-picture-logo {
  height: 12rem;
  margin-top: 5% !important;
  width: 10%;
  margin: auto;
  object-fit: contain;
}

.pricing-card-logo {
  max-width: 99%;
  border: 1px solid var(--clickable) !important;
  border-bottom: 6px solid var(--clickable) !important;
  margin-bottom: 10%;
}

.main-button-yellow {
  background-color: var(--yellow) !important;
  border-radius: 100px !important;
  border-color: var(--yellow) !important;
}

.main-button-yellow:hover {
  background-color: var(--feedback-bad) !important;
  border-radius: 100px !important;
  border-color: var(--feedback-bad) !important;
}

.main-button-yellow:active {
  background-color: var(--feedback-bad) !important;
  border-radius: 100px !important;
  border-color: var(--feedback-bad) !important;
}

.main-button-yellow:focus {
  background-color: var(--feedback-bad) !important;
  border-radius: 100px;
  border-color: var(--feedback-bad) !important;
}

.main-button-green {
  background-color: var(--green) !important;
  border-radius: 100px !important;
  border-color: var(--green) !important;
}

.main-button-green:hover {
  background-color: var(--feedback-very-good) !important;
  border-radius: 100px !important;
  border-color: var(--feedback-very-good) !important;
}

.main-button-green:active {
  background-color: var(--feedback-very-good) !important;
  border-radius: 100px !important;
  border-color: var(--feedback-very-good) !important;
}

.main-button-green:focus {
  background-color: var(--feedback-very-good) !important;
  border-radius: 100px;
  border-color: var(--feedback-very-good) !important;
}

.feature-card-1 {
  max-width: 99%;
  border: 1px solid var(--clickable) !important;
  border-bottom: 6px solid var(--clickable) !important;
  margin-bottom: 10%;
}

.feature-card-2 {
  max-width: 99%;
  border: 1px solid var(--green) !important;
  border-bottom: 6px solid var(--green) !important;
  margin-bottom: 10%;
}

.feature-card-3 {
  max-width: 99%;
  border: 1px solid var(--red) !important;
  border-bottom: 6px solid var(--red) !important;
  margin-bottom: 10%;
}

.feature-card-4 {
  max-width: 99%;
  border: 1px solid var(--yellow) !important;
  border-bottom: 6px solid var(--yellow) !important;
  margin-bottom: 10%;
}

.feature-card-5 {
  max-width: 99%;
  border: 1px solid var(--tikTokColour) !important;
  border-bottom: 6px solid var(--tikTokColour) !important;
  margin-bottom: 10%;
}
.feature-card-6 {
  max-width: 99%;
  border: 1px solid var(--activeColour) !important;
  border-bottom: 6px solid var(--activeColour) !important;
  margin-bottom: 10%;
}

.feature-card-7 {
  max-width: 99%;
  border: 1px solid var(--darkBlue) !important;
  border-bottom: 6px solid var(--darkBlue) !important;
  margin-bottom: 10%;
}

.feature-card-8 {
  max-width: 99%;
  border: 1px solid var(--feedback-very-good) !important;
  border-bottom: 6px solid var(--feedback-very-good) !important;
  margin-bottom: 10%;
}

.feature-card-picture {
  height: 8rem;

  margin: auto;
  margin-top: 20px;
  object-fit: scale-down;
}

.logo-divider {
  border-left: 1px solid var(--clickable);
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 1px;
}

.search-link-text {
  color: var(--darkBlue);
  font-weight: 500;
  font-size: large;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
}

.searchbar-text {
  font-size: large !important;
  color: var(--clickable) !important;
}

.tabbar-text-computer {
  font-size: small;
}

.year-dropdown {
  color: var(--clickable) !important;
  font-weight: 500 !important;
  font-size: 1.5rem !important;
}

.year-dropdown:hover {
  color: var(--clickableHovered) !important;
}

.page-subtext {
  /* color: var(--grey) !important; */
  font-weight: 400 !important;
  font-size: 1rem !important;
  text-align: center;
}

.footer-divider {
  /* background-color: var(--clickable); */
  border-bottom: 50px solid var(--clickable);
}

.footer-background {
  /* background-color: var(--clickable); */
  border-top: 5px solid var(--clickable);
  background-color: var(--grey-background);
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  /* padding-top: 100px; */
}

.footer-heading-text {
  color: white;
}

.footer-logos {
  /* background-color: white; */
  padding: 10px;
  border-radius: 10px;
}

.full-page-length {
  min-height: calc(100vh - 100px);
  padding-bottom: 100px;
}

.link {
  cursor: pointer;
}

.footer-link {
  font-weight: 400 !important;
  font-size: 1.2rem !important;
}

.footer-link:hover {
  color: var(--clickable) !important;
}

.subscription-details-box {
  width: 70%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--clickable);
}

@media screen and (max-width: 768px) {
  .subscription-details-box {
    width: 95%;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--clickable);
  }
}

.form-label-text {
  font-weight: 600 !important;
  font-size: large;
}

.form-submit-button {
  width: 90% !important;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.logo-divider-thin {
  /* border-left: 0.5px solid var(--clickable); */
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-card {
  width: 90%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  text-align: center;
  border: 1px solid var(--clickable);
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-card-variant-1 {
  border: 1px solid var(--clickable);
}

.custom-card-variant-2 {
  border: 1px solid var(--green);
}

.custom-card-variant-3 {
  border: 1px solid var(--yellow);
}

.custom-card-variant-basic {
  border: 1px solid var(--card-border-colour);
}

.custom-card-title {
  text-align: center;
  font-size: xx-large;
  font-weight: 900;
  color: var(--clickable);
  width: 100%;
  padding: 10px;
  border-bottom: 2px solid var(--card-border-colour);
}

.custom-card-title-variant-1 {
  color: var(--clickable);
}

.custom-card-title-variant-2 {
  color: var(--green);
}

.custom-card-title-variant-3 {
  color: var(--yellow);
}

.custom-card-title-variant-basic {
  border: 1px solid var(--card-border-colour);
  font-size: large;
  font-weight: 700;
  color: black;
}

.custom-card-text {
  text-align: center;
  font-size: 4rem;
  font-weight: 200;
  width: 100%;
  padding: 20px 0px;
  border-bottom: 2px solid var(--card-border-colour);
}

.custom-card-button {
  background-color: var(--clickable);
  border: none;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px;
}

.custom-card-button:hover {
  background-color: var(--clickableHovered);
}

.custom-card-button:disabled {
  background-color: var(--clickable-subtle);
}

.custom-card-button-variant-1 {
  background-color: var(--clickable);
}

.custom-card-button-variant-1:hover {
  background-color: var(--clickableHovered);
}

.custom-card-button-variant-2 {
  background-color: var(--green);
}

.custom-card-button-variant-2:hover {
  background-color: var(--green-hovered);
}

.custom-card-button-variant-3 {
  background-color: var(--yellow);
}

.custom-card-button-variant-3:hover {
  background-color: var(--yellow-hovered);
}

.custom-card-button-variant-delete {
  background-color: var(--red);
}

.custom-card-button-variant-delete:hover {
  background-color: var(--red-hovered);
}

.custom-card-button-variant-delete:disabled {
  background-color: var(--red-disabled);
}
.custom-loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--clickable); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: auto;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.list-item {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 90%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  border: 2px solid var(--clickable);
  border-radius: 5px;
}

.list-item-title {
  text-align: left;
  width: 30%;
  font-size: large;
  font-weight: 600;
  padding: 10px;
}

.list-item-text {
  text-align: left;
  width: 30%;
  font-size: medium;
  font-weight: 400;
  padding: 10px;
}

.list-item-button {
  background-color: var(--red);
  border: none;
  border-radius: 100px;
  color: white;
  font-weight: 800;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.list-item-button:hover {
  background-color: var(--red-hovered);
}

.qr-code-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.qr-box-border-1 {
  border: 2px solid var(--green);
}
.qr-box-border-2 {
  border: 2px solid var(--darkBlue);
}
.qr-box-border-3 {
  border: 2px solid var(--yellow);
}

.qr-code-img {
  width: 80%;
}

.qr-code-title {
  margin-top: 10px;
  z-index: 1;
  margin-bottom: -10px;
  text-align: center;
  font-weight: 800;
  font-size: xx-large;
  color: var(--clickable);
}

.white-box {
  width: 100%;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.white-box-small {
  width: 50%;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.instructions-list {
  font-weight: 500;
  font-size: larger;
  text-align: justify;
}

.instructions-text {
  margin-top: 5px;
}

.page-title {
  font-weight: 700;
  color: var(--clickable) !important;
}

@media screen and (max-width: 768px) {
  .page-title {
    font-weight: 700;
    color: var(--clickable) !important;
    text-align: center;
  }

  .white-box-small {
    width: 90%;
  }
}

.instructions-print {
  margin: 20px;
}

@media print {
  @page {
    size: landscape;
  }
}

.content-category-list {
  border-right: 2px solid var(--clickable);
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  margin: 0px;
  background-color: white;
}

.content-category-list-item {
  font-weight: 600;
  border: 2px solid var(--clickable-subtle);
  color: var(--clickable-subtle);
  border-radius: 10px;
  width: 95%;
  margin: auto;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.content-category-list-item-active {
  border: 2px solid var(--clickable);
  color: var(--clickable);
}

.content-category-list-item:hover {
  border: 2px solid var(--clickable);
  color: var(--clickable);
}

.content-item-container {
  width: 70% !important;
  background-color: white;
  border: 2px solid black;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: auto !important;
  margin-top: 30px;
  margin-bottom: 10px;
}

.content-item-container-variant-1 {
  border: 2px solid var(--clickable);
}

.content-item-box {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-item-title {
  font-size: x-large;
  font-weight: 600;
}

.show-mobile {
  display: none;
}

.show-web {
  display: inherit;
}

@media screen and (max-width: 768px) {
  .show-mobile {
    display: inherit;
  }

  .show-web {
    display: none;
  }

  .structured-lesson-video {
    width: 95%;
  }

  .structured-lesson-video-bordered {
    width: 95%;
  }

  .content-item-container {
    width: 100% !important;
  }

  .structured-content-text-box {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.return_arrow {
  color: var(--clickable);
  font-size: 2em;
  font-weight: 300;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.navbar-container {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.navbar-item {
  min-width: 100px;
}

.strand-icon-wrapper {
  width: 180px;
  height: 180px;
  /* margin: auto; */
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updated-strand-icon {
  object-fit: scale-down;
  width: 100% !important;
  height: 100% !important;
}

.school-container {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--hr-colour);
  min-height: 60vh;
  padding-bottom: 100px;
}

.right-divider {
  border-right: 1px solid var(--hr-colour);
}

.custom-button {
  background-color: var(--clickable);
  border: none;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  padding: 5px 20px;
  float: right;
  text-decoration: none;
  font-size: 16px;
  margin-left: auto;
}

.custom-button-big:disabled {
  opacity: 50%;
  background-color: var(--clickable);
}

.custom-button:hover {
  background-color: var(--clickableHovered);
}

.custom-button-big {
  background-color: var(--clickable);
  border: none;
  border-radius: 100px;
  color: white;
  font-weight: 600;
  padding: 10px 32px;
  text-decoration: none;
  font-size: 16px;
}

.custom-button-variant-2:disabled {
  background-color: var(--green);
}
.custom-button-variant-2 {
  background-color: var(--green);
}

.custom-button-big:disabled {
  background-color: var(--clickable) !important;
}
.custom-button-big:hover {
  background-color: var(--clickableHovered);
}

.custom-button-variant-2:hover {
  background-color: var(--green-hovered);
}

.custom-button-variant-good:disabled {
  background-color: var(--green) !important;
}

.custom-button-variant-good {
  background-color: var(--green);
}

.custom-button-variant-good:hover {
  background-color: var(--green-hovered);
}

.custom-button-variant-bad:disabled {
  background-color: var(--red) !important;
}
.custom-button-variant-bad {
  background-color: var(--red);
}

.custom-button-variant-bad:hover {
  background-color: var(--red-hovered);
}

.manage-school-tabs {
  margin-bottom: 2rem !important;
  color: var(--default-font-colour);
}

.manage-school-tabs button {
  color: var(--default-font-colour);
}

.manage-school-tabs button:hover {
  color: white;
  background-color: var(--clickable-subtle);
}

.manage-school-tabs button.active {
  color: white !important;
  background-color: var(--clickable) !important;
}

.header-text {
  font-size: xx-large;
}

.emphasis-text {
  font-weight: 600;
}

.table-sort {
  color: var(--clickable) !important;
  cursor: pointer;
}

.table-sort:hover {
  color: var(--clickableHovered) !important;
  text-decoration: underline;
}

.register-tabs {
  margin-bottom: 2rem !important;
  color: var(--default-font-colour);
}

.register-tabs button {
  color: var(--grey);
}

.register-tabs button:hover {
  color: var(--grey);
  /* background-color: var(--clickable-subtle); */
  cursor: auto;
}

.register-tabs button.active {
  color: white !important;
  background-color: var(--clickable) !important;
}

.equal-height {
  min-height: 40vh;
}

.mw-25 {
  min-width: "25%";
}

.hr-dark {
  border: 1px solid black !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.timer-container {
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-box {
  border: 5px solid black !important;
  border-radius: 100%;
  height: 90%;
  aspect-ratio: 1 / 1;

  padding: 20px;
}

.timer {
  /* text-align: center; */
  /* fill: var(--clickable) !important; */
  /* font-size: 16vh; */
  font-weight: 300;
  /* margin: auto; */
}

.vh-25 {
  min-height: 25vh;
}

.vh-35 {
  min-height: 35vh;
}

.vh-70 {
  min-height: 70vh;
}

.max-vh-35 {
  max-height: 35vh;
}

.max-vh-15 {
  max-height: 15vh;
  max-width: 30% !important;
}

.play-icon {
  font-weight: 500;
  font-size: 5em;
  color: var(--green) !important;
}

.pause-icon {
  font-weight: 500;
  font-size: 5em;
  color: var(--red) !important;
}

.congrats-text {
  font-weight: 500;
  color: var(--green) !important;
  text-align: center;
  font-size: 4rem;
}

.congrats-text-m {
  font-weight: 500;
  color: var(--green) !important;
  text-align: center;
  font-size: 2rem;
}

.congrats-icon {
  height: 250px;
  /* margin: auto; */
}

.congrats-icon-m {
  height: 170px;
  /* margin: auto; */
}

.text-normal {
  color: var(--default-font-colour) !important;
}

.text-green {
  color: var(--green) !important;
}

.text-red {
  color: var(--red) !important;
}

.workout-share-button {
  position: absolute;
  right: -15px;
  top: -15px;
  text-align: center;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  border: 2px solid var(--clickable);
  color: var(--clickable);
  font-weight: 600;
  font-size: x-large;
}

.workout-share-button:hover {
  position: absolute;
  right: -15px;
  top: -15px;
  text-align: center;
  width: 50px;
  height: 50px;
  background-color: var(--clickable);
  border-radius: 100%;
  /* border: 2px solid var(--clickableHovered); */
  color: white;
  font-weight: 600;
  font-size: x-large;
}

.workout-share-button.share-copied {
  position: absolute;
  right: -15px;
  top: -15px;
  text-align: center;
  width: 50px;
  height: 50px;
  background-color: var(--clickable);
  border-radius: 100%;
  /* border: 2px solid var(--clickableHovered); */
  color: white;
  font-weight: 600;
  font-size: x-large;
}

.center-icon {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.copy-icon {
  font-weight: inherit;
  font-size: inherit;
}

.highlight-grow {
  animation: card-grow-animation infinite 2s linear;
}

.workout-dropdown-placement {
  position: absolute;
  right: -15px;
  top: 30px;
}

.fms-box-unfinished {
  background-color: white;
  border: 2px solid var(--red);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.fms-box-finished {
  background-color: white;
  border: 2px solid var(--green);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.fms-checkbox {
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
  cursor: pointer;
}

.fms-checkbox-1 {
  border: 1px solid var(--feedback-very-bad);
  color: var(--feedback-very-bad);
}

.fms-checkbox-1-selected {
  border: 1px solid var(--feedback-very-bad);
  background-color: var(--feedback-very-bad);
  color: white;
}

.fms-checkbox-1:hover {
  background-color: var(--feedback-very-bad);
  color: white;
}

.fms-checkbox-2 {
  border: 1px solid var(--feedback-bad);
  color: var(--feedback-bad);
}

.fms-checkbox-2-selected {
  border: 1px solid var(--feedback-bad);
  background-color: var(--feedback-bad);
  color: white;
}

.fms-checkbox-2:hover {
  background-color: var(--feedback-bad);
  color: white;
}

.fms-checkbox-3 {
  border: 1px solid var(--feedback-neutral);
  color: var(--feedback-neutral);
}

.fms-checkbox-3-selected {
  border: 1px solid var(--feedback-neutral);
  background-color: var(--feedback-neutral);
  color: white;
}

.fms-checkbox-3:hover {
  background-color: var(--feedback-neutral);
  color: white;
}

.fms-checkbox-4 {
  border: 1px solid var(--feedback-good);
  color: var(--feedback-good);
}

.fms-checkbox-4-selected {
  border: 1px solid var(--feedback-good);
  background-color: var(--feedback-good);
  color: white;
}

.fms-checkbox-4:hover {
  background-color: var(--feedback-good);
  color: white;
}

.fms-checkbox-5 {
  border: 1px solid var(--feedback-very-good);
  color: var(--feedback-very-good);
}

.fms-checkbox-5-selected {
  border: 1px solid var(--feedback-very-good);
  background-color: var(--feedback-very-good);
  color: white;
}

.fms-checkbox-5:hover {
  background-color: var(--feedback-very-good);
  color: white;
}

.fms-checkbox-cell {
  min-width: 200px;
}

.fms-button-container {
  position: sticky;
  width: 100%;
  background-color: white;
  top: 0;
  z-index: 1;
  display: flex;
  padding: 10px;
  border: 1px solid var(--clickable);
  border-radius: 5px;
  align-items: center;
}

table {
  height: 1px;
} /* Will be ignored, don't worry. */
tr {
  height: 100%;
}
td {
  height: 100%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.search-icon-wrapper {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--default-font-colour);
}

.search-icon-wrapper:hover {
  border: 1px solid var(--clickable);
}

.pre-wrap {
  white-space: pre-wrap;
}
